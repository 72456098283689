<template>
    <v-badge style="float: right;" color="#005FAB" content="0" offset-x="12" offset-y="12">
        <v-btn small elevation="0" fab color="#FFD600"><v-icon small>mdi-bell</v-icon></v-btn><!-- to="/notificacoes" -->
    </v-badge>
</template>

<script>
export default {
    name: "NotificacoesIcone"
}
</script>

<style scoped>

</style>