<template>
    <div class="bg">
        <v-container fluid class="pt-0 mt-0">
            <v-row>
                <v-col cols="5">
                    <v-icon class="pt-9 pl-9" color="#005FAB" size="100px">mdi-translate</v-icon>
                </v-col>
                <v-col cols="7" class="pt-7 pr-7 pb-0">
                    <NotificacoesIcone/>
                    <h1>Financeiro</h1>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="pt-16 mt-5">
            <v-row>
                <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card class="rounded-xl">
                        <v-card-title style="color: #005FAB;">Excellent Global</v-card-title>
                        <v-divider/>
                        <v-card-text>
                            <p><strong>Telefone:</strong> -</p>
                            <p><strong>WhatsApp:</strong> -</p>
                            <p><strong>E-mail:</strong> -</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import NotificacoesIcone from "@/components/NotificacoesIcone";
export default {
    name: "Financeiro",
    components: {NotificacoesIcone},
    data: () => ({

    }),
}
</script>

<style scoped>
    h1, h2 {color: #005FAB;}
    h1 {text-align: left; width: 100%; padding-top:50px;  font-weight: lighter; font-size: 40px;}
    h2 {font-size: 20px;}
    .bg {background-image: url("~@/assets/img/bg-tradutor.png"); background-repeat: no-repeat; background-position-x: -50px; background-position-y: -50px;}
</style>